$(() => {
    let $body = $('body')

    /* Window height */
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    // Initiate gallery
    let $galeImg = $('.section.gallery .image_container img')
    $galeImg.first().addClass('active').addClass('first')
    $galeImg.last().addClass('last')

    // Gallery navigation
    $body.on('click', '.section.gallery', function (event) {
        let max = $(this).width()
        let cur = event.pageX
        let $galeImg = $('.section.gallery .image_container img')
        let $active = $('.section.gallery .image_container img.active')
        let $next = $active.next()
        let $prev = $active.prev()
        let $target, target

        if ((max * 0.5) > cur) {
            console.log('Prev')
            $target = $prev
            target = 'prev'
        } else {
            console.log('Next')
            $target = $next
            target = 'next'
        }

        if ($active.hasClass('last') && target === 'next') {
            $galeImg.first().addClass('active')
            $galeImg.last().removeClass('active')
        } else if ($active.hasClass('first') && target === 'prev') {
            $galeImg.first().removeClass('active')
            $galeImg.last().addClass('active')
        } else {
            $active.removeClass('active')
            $target.addClass('active')
        }
        gotoImage()


    })

    function gotoImage() {
        let $active = $('.section.gallery .image_container img.active')
        $active.get(0).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
    }

    // Page load animations
    gotoSection()
    console.log('gotoSection')

    function gotoSection() {

        if ($body.hasClass('home')) {
            console.log('home')
        } else if ($body.hasClass('programm')) {
            console.log('programm')
        } else if ($body.hasClass('info')) {
            console.log('info')
        } else if ($body.hasClass('events')) {
            console.log('events')
        } else if ($body.hasClass('gallery')) {
            console.log('gallery')
        } else if ($body.hasClass('contact')) {
            console.log('contact')
        }
    }

});
